@use "assets/scss/common.scss";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";

body {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 400;
    color: #000000;
}

#header {
    .header-up {
        transition: all 0.4s ease-out;
        -ms-transform: translateY(-100%);
        -webkit-transform: translateY(-100%);
        -o-transform: translateY(-100%);
        -moz-transform: translateY(-100%);
        transform: translateY(-100%);
    }

    .header-down {
        transition: all 0.4s ease-out;
        -ms-transform: translateY(0);
        -webkit-transform: translateY(0);
        -o-transform: translateY(0);
        -moz-transform: translateY(0);
        transform: translateY(0);
    }
}

a:hover,
a.is-active,
a.is-active-item,
.item-ecosystem.is-active {
    transition: all 0.3s ease-out;
    color: var(--color-main);
}

.wrapper {
    .owl-carousel .owl-nav {
        position: absolute;
        margin: 0 !important;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        .next-slide,
        .prev-slide {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            span {
                font-size: 42px;
            }
        }

        .owl-next {
            top: 50%;
            transform: translateY(-50%);
            right: 0px;
            position: absolute;
            background: #ffffff;
            border-radius: 100%;
            padding: 0;
            margin: 0;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
            color: rgba(241, 117, 34, 0.5);
            width: auto;
            height: auto;

            &:hover {
                color: rgba(241, 117, 34, 1);
                background: #ffffff;
            }
        }

        .owl-prev {
            top: 50%;
            transform: translateY(-50%);
            left: 0px;
            position: absolute;
            background: #ffffff;
            border-radius: 100%;
            padding: 0;
            margin: 0;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
            color: rgba(241, 117, 34, 0.5);
            width: auto;
            height: auto;
            &:hover {
                color: rgba(241, 117, 34, 1);
                background: #ffffff;
            }
        }
    }
}

.member-slider.wrapper {
    .owl-carousel .owl-nav {
        transform: translateY(-100%);
        .owl-next {
            transform: translateY(-100%);
            right: -20px;
        }

        .owl-prev {
            top: 50%;
            transform: translateY(-100%);
            left: -20px;
        }
        img {
            width: 40px;
            height: 40px;
        }
    }
}

.list-carousel-member-fidra.wrapper {
    .owl-carousel .owl-nav {
        transform: translateY(-100%);
        .owl-next {
            transform: translateY(-80%);
            right: 0px;
        }

        .owl-prev {
            top: 50%;
            transform: translateY(-80%);
            left: 0px;
        }
        img {
            width: 40px;
            height: 40px;
        }
    }
}

.slider-common {
    .owl-carousel .owl-nav {
        position: absolute;
        margin: 0 !important;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        .next-slide,
        .prev-slide {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            span {
                font-size: 42px;
            }
        }

        .owl-next {
            top: 50%;
            transform: translateY(-100%);
            right: -20px;
            position: absolute;
            background: #ffffff;
            border-radius: 100%;
            padding: 0;
            margin: 0;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
            color: rgba(241, 117, 34, 0.5);
            width: auto;
            height: auto;

            // &:hover {
            //     color: rgba(241, 117, 34, 1);
            //     background: #ffffff;
            // }
        }

        .owl-prev {
            top: 50%;
            transform: translateY(-100%);
            left: -20px;
            position: absolute;
            background: #ffffff;
            border-radius: 100%;
            padding: 0;
            margin: 0;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
            color: rgba(241, 117, 34, 0.5);
            width: auto;
            height: auto;
            // &:hover {
            //     color: rgba(241, 117, 34, 1);
            //     background: #ffffff;
            // }
        }
    }
}

.slider-activity {
    .owl-carousel .owl-nav {
        position: absolute;
        margin: 0 !important;
        top: 30%;
        // transform: translateY(-50%);
        width: 100%;

        .owl-next {
            top: 0px;
            transform: translateY(70%);
            right: 0px;
            position: absolute;
            border-radius: 100%;
            padding: 0;
            margin: 0;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
            width: auto;
            height: auto;
        }

        .owl-prev {
            top: 0px;
            transform: translateY(70%);
            left: 0px;
            position: absolute;
            border-radius: 100%;
            padding: 0;
            margin: 0;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
            width: auto;
            height: auto;
        }
    }
}
@media only screen and (max-width: 600px) {
    .slider-activity {
        .owl-carousel .owl-nav {
            .owl-next {
                width: 25px;
                height: 25px;
            }
            .owl-prev {
                width: 25px;
                height: 25px;
            }
        }
    }
}
.p-progress-spinner-circle {
    stroke: #fec00f !important;
}
.toast-warning {
    background-color: #fec00f !important;
}

::-webkit-media-controls {
    display: none;
}
body::-webkit-scrollbar {
    width: 8px;
}

body::-webkit-scrollbar-track {
    // background: orange; /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
    background-color: #fec00f;
    border-radius: 20px;
}
